import { DesignsystemProvider, extendTheme } from '@hausgold/designsystem';
import Head from 'next/head';
import { config, isLocal } from 'app-config';
import { isIframe } from 'app-utils/isEnv';
import PropTypes from 'prop-types';
import React from 'react';
import Script from 'next/script';
import * as snippet from '@segment/snippet';
import useTracking from '../hooks/useTracking';
import useUrlParams from '../hooks/useUrlParams';

const theme = extendTheme({
  styles: {
    global: () => ({
      'html, body, #__next, .layout': {
        h: '100%',
        bg: 'inherit',
      },
    }),
  },
});

// Static Hausgold domain used by our company
const HAUSGOLD_DOMAIN = 'hausgold.de';

// Setup formwizard for `<iframe />` usage
if (isIframe) {
  try {
    // Set `document.domain` in iframe (and parent website) to get around CORS.
    document.domain = HAUSGOLD_DOMAIN;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.info(
      "Formwizard <iframe /> setup failed. You have to be on '*.hausgold.de'."
    );
  }
}

function App({ Component, pageProps }) {
  const urlParams = useUrlParams();
  const doTrack = useTracking();

  const renderSegmentSnippet = () => {
    const opts = {
      apiKey: config.analytics.segmentKey,
      // We do NOT want to track pages because it causes a segment call overload because the Wizard is built into
      // every page of hausgold.de
      //
      // We track the steps via segment events inside the formwizard itself.
      page: false,
    };

    if (isLocal) {
      return snippet.max(opts);
    }

    return snippet.min(opts);
  };

  return (
    <DesignsystemProvider includeCssVarPolyfill theme={theme} fonts={false}>
      {urlParams.isReady && doTrack && (
        <Script
          dangerouslySetInnerHTML={{ __html: renderSegmentSnippet() }}
          strategy="afterInteractive"
        />
      )}

      {/*
        We need the title here because the test-suite is checking for it
        and will refuse to run if the title doesn't match
      */}
      <Head>
        <title>Formwizard HAUSGOLD</title>
      </Head>
      {
        // this is used in order to ensure url params are available if there are any
        // if we don't check the ready state of the router we will run into an issue
        // where the formwizard will use the default config on the first render and
        // rerenders once the url parameters are available
        // https://github.com/vercel/next.js/issues/8259
        urlParams.isReady && <Component {...pageProps} />
      }
    </DesignsystemProvider>
  );
}

App.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.object.isRequired,
};

export default App;
