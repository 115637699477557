import { useRouter } from 'next/router';

/**
 * `disableTracking` retrieval logic
 *
 * @return {boolean}
 */
const useTracking = () => {
  const { query } = useRouter();

  if (typeof query?.disableTracking === 'undefined') return true;

  return query?.disableTracking === '0';
};

export default useTracking;
