import { useRouter } from 'next/router';

/**
 * Extract param `dialogId` from URL.
 * @returns {{dialogId: (""|*)}}
 */
const useUrlParams = () => {
  const router = useRouter();
  const { query, isReady } = router;
  const dialogId = query?.id && query?.id[0];

  return { dialogId, isReady };
};

export default useUrlParams;
